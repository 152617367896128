import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

const firebaseApp = firebase.initializeApp ({
  apiKey: "AIzaSyB_Z9X8vcDEjegY7BZJtKI6jq9w_pet8hw",
  authDomain: "podana-7e6d6.firebaseapp.com",
  projectId: "podana-7e6d6",
  storageBucket: "podana-7e6d6.appspot.com",
  messagingSenderId: "852027548602",
  appId: "1:852027548602:web:4b6c29d9f435fe655ef383",
  measurementId: "G-LQC9FPT57W"
});

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const functions = firebase.functions();

export { db, auth, storage, functions, firebase };