import React from 'react';

function SiteOffline() {
    return (
        <div style={styles.container}>
            <p style={styles.text}>The Podana website is temporarily closed, but you can continue using our app.</p>
            <p style={styles.text}>Support: Whatsapp +55 (47) 99184-9056 (Send messages in english or envie mensagens em português (Brasil))</p>
        </div>
    );
}

const styles = {
    container: {
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
    },
    text: {
        fontSize: '18px',
        padding: '20px',
    },
};

export default SiteOffline;
