import { useTranslation } from "react-i18next";
import '../css/Privacy.css';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";

export default function Privacy() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/');
    };
    const [polTerms, setPolTerms] = useState(false);

    return (
        <div className="classPolPriv">
            <div className="topBarPrivPolicy">
                <div className="left">
                    <span onClick={handleBackClick} className="topBtn">{t('btnBack')}</span>
                </div>
                <div className="center">
                    <span>
                        <span onClick={() => setPolTerms(false)} className="topBtn">{t('login.privPolStr')}</span>
                        <span> | </span>
                        <span onClick={() => setPolTerms(true)} className="topBtn">{t('login.terms')}</span>
                    </span>
                </div>
            </div>

            <div style={{ paddingTop: '60px' }}>
                { !polTerms ?
                    <div className="container">
                        <h1>Política de Privacidade do Podana</h1>
                        <p>Esta Política de Privacidade descreve como o Podana coleta, utiliza, armazena e protege os dados pessoais dos usuários. Ao utilizar o aplicativo Podana, você concorda com as práticas descritas nesta política.</p>
                        
                        <h2>Dados Coletados</h2>
                        <ul>
                            <li><strong>Informações de Cadastro:</strong>
                                <ul>
                                    <li>Nome, endereço de e-mail e senha para autenticação.</li>
                                </ul>
                            </li>
                            <li><strong>Informações de Pacientes:</strong>
                                <ul>
                                    <li>Nome, endereço, número de telefone, problemas de saúde relacionados a pés e mãos, alergias, condições médicas como diabetes e detalhes do histórico médico.</li>
                                </ul>
                            </li>
                            <li><strong>Registros de Evolução:</strong>
                                <ul>
                                    <li>Descrições, imagens e notas sobre a evolução do problema do paciente, armazenados em uma galeria.</li>
                                </ul>
                            </li>
                        </ul>

                        <h2>Uso dos Dados</h2>
                        <p>Os dados pessoais coletados são utilizados para os seguintes propósitos:</p>
                        <ul>
                            <li>Autenticação e acesso seguro à plataforma.</li>
                            <li>Registro e acompanhamento da evolução dos problemas dos pacientes.</li>
                            <li>Comunicação com os usuários sobre suas contas e serviços relacionados.</li>
                            <li>Melhoria contínua do aplicativo e serviços.</li>
                        </ul>

                        <h2>Armazenamento e Segurança dos Dados</h2>
                        <ul>
                            <li>As informações de cadastro são armazenadas no Firebase, que oferece medidas de segurança robustas para proteger os dados.</li>
                            <li>As senhas não são armazenadas em texto simples no banco de dados; elas são criptografadas no Firebase.</li>
                            <li>Os registros de pacientes e evolução são armazenados em servidores seguros e acessados apenas por profissionais de saúde autorizados.</li>
                            <li>Implementamos medidas técnicas e organizacionais para proteger os dados contra acessos não autorizados e vazamentos.</li>
                        </ul>

                        <h2>Compartilhamento de Dados</h2>
                        <ul>
                            <li>Os dados dos pacientes e registros de evolução não são compartilhados com terceiros sem o consentimento explícito do usuário.</li>
                            <li>O acesso a esses dados é restrito a profissionais de saúde autorizados, garantindo a privacidade dos pacientes.</li>
                        </ul>

                        <h2>Direitos dos Usuários</h2>
                        <p>Os usuários possuem os seguintes direitos relacionados aos seus dados pessoais:</p>
                        <ul>
                            <li>Acesso aos dados pessoais coletados.</li>
                            <li>Correção de informações imprecisas.</li>
                            <li>Exclusão de dados, sujeito a obrigações legais de retenção.</li>
                            <li>Revogação do consentimento para o processamento dos dados.</li>
                        </ul>
                        <p>Para exercer esses direitos, os usuários podem entrar em contato pelo chat dentro do aplicativo ou via e-mail para <a href="mailto:sinhoripedro@hotmail.com">sinhoripedro@hotmail.com</a>.</p>

                        <h2>Contato</h2>
                        <p>Se você tiver dúvidas sobre esta política de privacidade ou desejar exercer seus direitos de proteção de dados, entre em contato conosco pelo e-mail <a href="mailto:sinhoripedro@hotmail.com">sinhoripedro@hotmail.com</a>.</p>

                        <h2>Alterações na Política</h2>
                        <p>Esta política de privacidade poderá ser atualizada periodicamente para refletir mudanças nas práticas de privacidade. Alterações na política serão comunicadas aos usuários através do aplicativo Podana. Os usuários deverão aceitar os novos termos para continuar utilizando o sistema. Caso não aceitem, não poderão utilizar o sistema.</p>
                    </div>
                :
                    <div className="container">
                        <h1>Termos de Uso do Podana</h1>
                        <h2>1. Informações Gerais</h2>
                        <p>Bem-vindo ao Podana, um sistema dedicado à criação de fichas de anamnese para podologia. Ao criar uma conta no Podana, você concorda com estes Termos de Uso e a Política de Privacidade associada.</p>
                        
                        <h2>2. Uso do Serviço</h2>
                        <ul>
                            <li>Ao utilizar o Podana, você concorda em seguir estes Termos de Uso e a Política de Privacidade.</li>
                            <li>Você não deve utilizar a plataforma para quaisquer atividades ilícitas ou violar as leis locais.</li>
                        </ul>
        
                        <h2>3. Responsabilidades do Usuário</h2>
                        <ul>
                            <li>O usuário é responsável por usar a plataforma de maneira lícita e de acordo com todas as leis locais aplicáveis.</li>
                            <li>É proibido usar a plataforma para compartilhar ou armazenar informações ilícitas.</li>
                        </ul>
        
                        <h2>4. Direitos da Empresa</h2>
                        <ul>
                            <li>O Podana reserva-se o direito de modificar ou encerrar os serviços a qualquer momento, com ou sem aviso prévio.</li>
                            <li>A empresa pode acessar o conteúdo da plataforma para manutenção e suporte técnico.</li>
                            <li>Os Termos de Uso e a Política de Privacidade podem ser alterados a qualquer momento sem aviso prévio.</li>
                        </ul>
        
                        <h2>5. Limitação de Responsabilidade</h2>
                        <ul>
                            <li>O usuário é inteiramente responsável por suas ações ao utilizar a plataforma.</li>
                            <li>O Podana não se responsabiliza por quaisquer problemas decorrentes do uso indevido do sistema pelos usuários.</li>
                        </ul>
        
                        <h2>6. Política de Reembolso/Cobrança</h2>
                        <ul>
                            <li>As cobranças são processadas pelas bibliotecas do Google Play Store e da Apple App Store.</li>
                            <li>Reembolsos devem ser solicitados através das plataformas onde a compra foi realizada (Google Play Store ou Apple App Store).</li>
                            <li>O usuário pode utilizar o sistema gratuitamente com anúncios ou optar pelo tempo premium através das compras nas lojas de aplicativos.</li>
                            <li>O preço do serviço premium pode ser alterado a qualquer momento sem aviso prévio.</li>
                        </ul>
        
                        <h2>7. Rescisão</h2>
                        <ul>
                            <li>O usuário pode cancelar e encerrar sua conta a qualquer momento, removendo todos os dados associados.</li>
                            <li>Após a confirmação da exclusão dos dados, não será possível recuperar as informações caso o usuário decida retornar à plataforma.</li>
                        </ul>
        
                        <h2>8. Disposições Gerais</h2>
                        <ul>
                            <li>Este acordo é regido pelas leis locais, incluindo a Lei Geral de Proteção de Dados (LGPD) e outras leis internacionais aplicáveis.</li>
                            <li>Alterações nos Termos de Uso serão comunicadas aos usuários através do aplicativo Podana, e a continuação do uso do sistema implicará na aceitação dos novos termos.</li>
                        </ul>
        
                        <h2>9. Contato</h2>
                        <p>Para dúvidas, suporte ou questões relacionadas aos Termos de Uso, entre em contato pelo e-mail <a href="mailto:sinhoripedro@hotmail.com">sinhoripedro@hotmail.com</a>.</p>
                    </div>
                }
            </div>
        </div>
    );
}
