import './App.css';
import Login from './pages/Login.js';
import Home from './pages/Home.js';
import { useEffect, useState } from 'react';
import { auth, db } from "./firebase";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Privacy from './pages/Privacy';
import { useTranslation } from "react-i18next";
import SiteOffline from './pages/SiteOffline.js';

function App() {
  
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [accType, setAccType] = useState('');
  
  const { i18n } = useTranslation();

  useEffect(() => {    
    i18n.changeLanguage(navigator.language);
  }, [])

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if(!user){
        setLoggedIn(false);
      }else{
        db
        .collection('users')
        .doc(auth.currentUser.uid)
        .onSnapshot(snap => {
          if(snap.exists){
            let data = snap.data();
            setAccType(data.accType)
            setLoggedIn(true);
          }
        })
        /*.then(() => {
          setLoggedIn(true);
        })*/
        /*let dbRef = 
          db.collection('customers')
            .doc(auth.currentUser.uid);

          dbRef
          .collection('subscriptions')
          .where('status', '==', 'active')
          .get()
          .then(snap => {
            snap.forEach(subs => {
              const subscriptionData = subs.data();
              const subscriptionId = subs.id;
              console.log(`ID => ${subscriptionId}`);
              console.log(`status => ${subscriptionData.status}`);
              
              setSubscription({
                current_period_start: subscriptionData.current_period_start,
                current_period_end: subscriptionData.current_period_end,
                status: subscriptionData.status
              });

            })
          })
          .then(() => {
            dbRef
            .collection('checkout_sessions')

          })
          .then(() => {
            db
            .collection('users')
            .doc(auth.currentUser.uid)
            .onSnapshot(snap => {
              if(snap.exists){
                let data = snap.data();
                setAccType(data.accType)
              }
            })
          })
          .then(() => {
            setLoggedIn(true);
          })*/
      }
    });
  }, [auth]);

  return (
    <div>
      {/*isAndroid || isIOS ? 

          isWebView ?

            <Router>
              <div className="App">
                <Routes>
                  <Route path="/" element={isLoggedIn ? <Home subscription={subscription} accType={accType} /> : <Login />} />
                  <Route path="/privacy" element={<Privacy />} />
                </Routes>
              </div>
            </Router>

          :

            <div>
              <Mobile />
            </div>

        :
          <Router>
            <div className="App">
              <Routes>
                <Route path="/" element={isLoggedIn ? <Home subscription={subscription} accType={accType} /> : <Login />} />
                <Route path="/privacy" element={<Privacy />} />
              </Routes>
            </div>
          </Router>*/
      }
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<SiteOffline />/*isLoggedIn ? <Home accType={accType} /> : <Login />*/} />
            <Route path="/privacy" element={<Privacy />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
